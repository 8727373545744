<template>
  <div
    class="main"
    :class="{'phone': mq.phone}"
  >
    <h2>
      <router-link
        v-if="!isInCatalogManager"
        to="/catalog"
      >
        {{ $t('router.views.Indicators.label') }}
      </router-link>
      <router-link
        v-else
        to="/catalogManager"
      >
        {{ $t('router.views.Catalog.catalogAdmin') }}
      </router-link>
      <span class="separator">
        >
      </span>
      <span
        class="indicator-name"
        :title="indicator.title"
      >
        {{ indicator.title }}
      </span>
    </h2>
    <iframe
      :src="indicatorUrl"
      class="indicator"
      frameborder="0"
    />
  </div>
</template>

<script>

export default {
  name: 'Indicator',
  inject: ['mq'],
  props: {
    indicator: {
      type: Object,
      required: true
    }
  },
  computed: {
    isInCatalogManager () {
      return this.$route.name === 'adminIndicator'
    },
    indicatorUrl () {
      console.log(document.cookie)
      return '/shiny' + this.indicator.localUrl +
        '?indicatorId=' + (this.indicator.parentIndicatorId !== 0 ? this.indicator.parentIndicatorId : this.indicator.indicatorId) +
        '&canCustom=true' + // Because we are in authentified part
        '&params=' + this.indicator.urlParams
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.main {
  margin: 0 10px;
  height: calc(100% - #{$desktop-search-bar-height});
  display: flex;
  flex-direction: column;

  h2 {
    margin: 0;
    height: 60px;
    display: flex;
    align-items: center;

    .separator {
      display: flex;
      justify-content: center;
      min-width: 20px;
    }

    .indicator-name {
      flex: 1;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 3rem;
    }
  }

  .indicator {
    flex: 1;
    width: 100%;
  }

  &.phone {
    margin: 0;
    height: calc(100% - #{$mobile-search-bar-height});

    h2 {
      font-size: 1rem;
      height: 50px;
      padding-left: 10px;

      .indicator-name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.5rem;
      }
    }
  }
}

</style>
