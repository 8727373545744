<template>
  <IndicatorForm
    v-if="editIndicator"
    :initiator-indicator="indicatorToUpdate"
    @close="toggleEditIndicator"
  />
  <div v-else>
    <button
      class="icon-container"
      @click="closePanel"
    >
      <BaseIcon
        class="close-panel"
        name="times"
      />
    </button>
    <hr>
    <div class="header">
      <h2 v-t="'router.views.Catalog.libraryHeader'" />
    </div>
    <IndicatorList
      class="list"
      :indicator-list="indicatorList"
      :admin-mode="adminMode"
      @openUpdateModal="openUpdateModal"
      @displayIndicatorForm="toggleEditIndicator"
    />
  </div>
</template>

<script>
import BaseIcon from '@components/Base/BaseIcon'
import IndicatorList from '@components/Indicator/Library/IndicatorList'
import IndicatorForm from '@components/Indicator/Library/IndicatorForm'

export default {
  name: 'Library',
  components: {
    IndicatorForm,
    BaseIcon,
    IndicatorList
  },
  props: {
    adminMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  data () {
    return {
      editIndicator: false,
      indicatorToUpdate: undefined
    }
  },
  computed: {
    indicatorList () {
      return this.$store.getters['library/sortedIndicatorList'] || []
    }
  },
  mounted () {
    if (this.$store.state.library.indicatorList === undefined) {
      this.$store.dispatch('library/initIndicatorList')
    }
  },
  methods: {
    openUpdateModal (indicator) {
      this.indicatorToUpdate = indicator
      this.editIndicator = true
    },
    toggleEditIndicator () {
      // Reset indicatorToUpdate on component closure
      if (this.editIndicator) {
        this.indicatorToUpdate = undefined
      }

      this.editIndicator = !this.editIndicator
    },
    closePanel () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-container {
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  border-radius: 20px;
  border: none;

  .close-panel {
    font-size: 18px;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 20px;
  margin-bottom: 50px;
}

.list {
  width: 60%;
  margin: auto;
}

.update {
  display: block;
  width: 98%;
  margin: auto;
  margin-top: 30px;
  border-radius: 15px;
}
</style>
