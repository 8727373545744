<template>
  <AuthenticatedLayout v-if="isAdministrator || isCadycoAdministrator">
    <Indicator
      v-if="indicator"
      :indicator="indicator"
    />
    <Library
      v-else-if="isLibraryShown"
      :admin-mode="true"
      @close="toggleLibraryDisplay"
    />
    <div
      v-else
      class="container"
      :class="{'phone': mq.phone}"
    >
      <div class="header">
        <h2 v-t="'router.views.Catalog.catalogAdminHeader'" />
        <a
          @click="toggleLibraryDisplay"
        >
          <BaseIcon
            class="close-panel"
            name="cog"
          />
          {{ $t('router.views.Catalog.libraryHeader') }}
        </a>
      </div>
      <hr>
      <div class="catalog-manager">
        <ProfileList class="roles" />
        <Catalog
          v-if="catalog"
          :admin-mode="true"
          :catalog="catalog"
          :title="title"
          class="catalog"
        />
      </div>
    </div>
  </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@layouts/AuthenticatedLayout'

import BaseIcon from '@components/Base/BaseIcon'
import Catalog from '@components/Indicator/Catalog/Catalog'
import Library from '@components/Indicator/Library/Library'
import ProfileList from '@components/Indicator/Catalog/ProfileList'
import Indicator from '@components/Indicator/Catalog/Indicator'

export default {
  name: 'CatalogManager',
  components: {
    Indicator,
    BaseIcon,
    AuthenticatedLayout,
    Catalog,
    Library,
    ProfileList
  },
  inject: ['mq'],
  data: function () {
    return {
      isLibraryShown: false
    }
  },
  computed: {
    isAdministrator () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.isAdministrator
      }
      this.$store.dispatch('auth/getUserInfos')
      return false
    },
    isCadycoAdministrator () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.isCadycoAdministrator
      }
      this.$store.dispatch('auth/getUserInfos')
      return false
    },
    catalog () {
      return this.$store.state.catalog.profileCatalog
    },
    title () {
      return this.$store.state.catalog.selectedProfile !== undefined
        ? this.$store.state.catalog.selectedProfile.title : ''
    },
    indicator () {
      let indicator
      if (this.$route.params.indicatorId) {
        if (this.isLibraryShown) {
          const i = this.$store.getters['library/sortedIndicatorList'].map(item => item.indicatorId).indexOf(this.$route.params.indicatorId)
          if (i > -1) {
            indicator = this.$store.getters['library/sortedIndicatorList'][i]
            return indicator
          }
        } else {
          for (const category of this.catalog.categoryList) {
            const i = category.indicatorList.map(item => item.indicatorId).indexOf(this.$route.params.indicatorId)
            if (i > -1) {
              indicator = category.indicatorList[i]
              return indicator
            }
          }
        }
      }
      return undefined
    }
  },
  methods: {
    toggleLibraryDisplay () {
      this.isLibraryShown = !this.isLibraryShown
    }
  }
}
</script>

<style lang="scss" scoped>
 @import '@design';

.container {
  position: relative;
  height: calc(100% - #{$desktop-search-bar-height});
  overflow: auto;
  //padding: 30px 20px 0 20px;

  &.phone {
    height: calc(100% - #{$mobile-search-bar-height});
  }
}

.catalog-manager {
  display: flex;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 20px;
}

.roles {
  width: 30%;
  border-right: 1px solid black;
}

.catalog {
  width: 70%;
  padding-left: 15px;
}

a{
  cursor: pointer;
}
</style>
