<template>
  <div>
    <h2
      v-t="'router.views.Catalog.profileHeader'"
      class="title"
    />
    <div
      v-for="profile in profileList"
      :key="profile.title"
      class="profile"
      :class="{'selected': (profile.title === seletectedProfile.title)}"
      @click="selectProfile(profile)"
    >
      <div
        class="thumbnail"
        :class="{'theme-background-color': (profile.title === seletectedProfile.title)}"
      />
      <label :title="profile.title">{{ profile.title }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileList',
  computed: {
    profileList () {
      return this.$store.getters['catalog/sortedProfileList'] || []
    },
    seletectedProfile () {
      return this.$store.state.catalog.selectedProfile || {}
    }
  },
  mounted () {
    // TODO change to dispatch + select first profile
    if (this.$store.state.catalog.profileList === undefined) {
      this.$store.dispatch('catalog/initProfileList')
    }
  },
  methods: {
    selectProfile (profile) {
      this.$store.dispatch('catalog/selectProfile', profile)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.title {
  margin-left: 10px;
}

.profile {
  display: flex;
  align-items: center;
  margin: 5px 0 5px 5px;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;

  .thumbnail {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 5px;

    &:not(.theme-background-color) {
      background-color: $color-default-community-thumbnail;
    }
  }

  label {
    flex: 1;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.selected {
  background-color: $color-selected-bg;
}
</style>
