<template>
  <div>
    <button
      class="icon-container"
      @click="closePanel"
    >
      <BaseIcon
        class="close-panel"
        name="times"
      />
    </button>
    <hr>
    <h2 v-t="(initiatorIndicator===undefined) ? 'router.views.Catalog.libraryHeaderAddIndicator' : 'router.views.Catalog.libraryHeaderUpdateIndicator'" />
    <br>

    <!-- Step 1 : json file picker -->
    <div v-if="jsonPickerWizardStep">
      <Input
        v-model="jsonPath"
        class="field"
        :placeholder="$t('router.views.Indicators.jsonFilePath')"
      />
      <Button
        :label="$t('router.views.Indicators.parseJson')"
        class="next"
        @click="parseJson"
      />
    </div>

    <!-- The form itself, for creation and edition -->
    <div
      v-if="!jsonPickerWizardStep"
      class="form"
    >
      <p>{{ $t('router.views.Indicators.New.name') }}</p>
      <p>{{ indicatorTitle }}</p>

      <p>{{ $t('router.views.Indicators.New.shortDescription') }}</p>
      <p>{{ indicatorShortDescription }}</p>

      <p>{{ $t('router.views.Indicators.New.longDescription') }}</p>
      <p>{{ indicatorLongDescription }}</p>

      <p>{{ $t('router.views.Indicators.New.Dimensions') }}</p>
      <p>{{ indicatorDimensions }}</p>

      <p>{{ $t('router.views.Indicators.New.shortLicense') }}</p>
      <p>{{ indicatorShortLicence }}</p>

      <p>{{ $t('router.views.Indicators.New.shortSpdxLicense') }}</p>
      <p>{{ indicatorShortSpdxLicence }}</p>

      <p>{{ $t('router.views.Indicators.New.longLicense') }}</p>
      <p>{{ indicatorLongLicence }}</p>

      <p>{{ $t('router.views.Indicators.New.version') }}</p>
      <p>{{ indicatorVersion }}</p>

      <p>{{ $t('router.views.Indicators.New.localUrl') }}</p>
      <p>{{ indicatorLocalUrl }}</p>

      <p>{{ $t('router.views.Indicators.New.publicUrl') }}</p>
      <div class="url-test">
        <p>{{ indicatorPublicUrl }}</p>
        <Button
          :label="$t('router.views.Indicators.New.testUrl')"
          class="test"
          @click="testUrl"
        />
        <p
          v-if="isTestDone && isTestOK"
          class="test-ok"
        >
          {{ $t('router.views.Indicators.New.testOK') }}
        </p>
        <p
          v-if="isTestDone && !isTestOK"
          class="test-ko"
        >
          {{ $t('router.views.Indicators.New.testKO') }}
        </p>
      </div>

      <p>{{ $t('router.views.Indicators.New.date') }}</p>
      <p>{{ indicatorDate }}</p>

      <p>{{ $t('router.views.Indicators.New.gitHashKey') }}</p>
      <p>{{ indicatorGitHashKey }}</p>

      <p>{{ $t('router.views.Indicators.New.uriParam') }}</p>
      <p>{{ indicatorUriParam }}</p>

      <p>{{ $t('router.views.Indicators.New.uriParamSchema') }}</p>
      <p>{{ indicatorUriParamSchema }}</p>

      <p>{{ $t('router.views.Indicators.New.maintenance') }}</p>
      <Checkbox
        v-model="indicatorOnMaintenance"
        class="maintenance"
      />
    </div>
    <div
      v-if="!jsonPickerWizardStep"
      class="indicator-picture-container"
    >
      <div
        class="indicator-picture"
        @click="openImagePickerModal"
      >
        <div
          v-if="URIIndicatorPicture === undefined"
          v-t="'router.views.Indicators.addIndicatorPicture'"
          class="indicator-picture-placeholder"
        />
        <UserPicture
          v-else
          :image-url="URIIndicatorPicture"
        />
        <div class="error-message">
          {{ error.picture }}
        </div>
      </div>
    </div>
    <Button
      v-if="!jsonPickerWizardStep"
      :label="(initiatorIndicator===undefined) ? $t('router.views.Catalog.add') : $t('router.views.Catalog.update')"
      class="dark"
      @click="confirm"
    />
  </div>
</template>

<script>
import BaseIcon from '@components/Base/BaseIcon'
import libraryService from '@/api/indicator/library.service'

export default {
  name: 'IndicatorForm',
  components: { BaseIcon },
  props: {
    initiatorIndicator: {
      type: Object,
      default: undefined
    }
  },
  emits: ['close'],
  data () {
    return {
      jsonPickerWizardStep: true,
      jsonPath: '',
      jsonContent: '',
      indicatorTitle: '',
      indicatorShortDescription: '',
      indicatorLongDescription: '',
      indicatorDimensions: '',
      indicatorShortLicence: '',
      indicatorShortSpdxLicence: '',
      indicatorLongLicence: '',
      indicatorVersion: '',
      indicatorLocalUrl: '',
      indicatorPublicUrl: '',
      indicatorDate: '',
      indicatorGitHashKey: '',
      indicatorUrlParams: '',
      indicatorUrlParamsSchema: '',
      indicatorOnMaintenance: true,
      URIIndicatorPicture: undefined,
      indicatorPictureBlob: undefined,
      error: {
        title: '',
        picture: ''
      },
      isTestDone: false,
      isTestOK: false
    }
  },
  mounted () {
    this.jsonPickerWizardStep = true
    this.jsonContent = ''
  },
  methods: {
    openImagePickerModal () {
      const initializerPictureURL = this.initiatorIndicator ? this.initiatorIndicator.thumbnailUrl : undefined
      this.$store.dispatch('modals/openImagePickerModal', { onConfirm: this.onPictureSave, initializerPictureURL: initializerPictureURL })
    },
    onPictureSave (imageBlob) {
      this.indicatorPictureBlob = imageBlob
      this.URIIndicatorPicture = URL.createObjectURL(imageBlob)
    },
    confirm () {
      if (this.indicatorTitle === '') {
        this.error.title = this.$t('router.views.Indicators.Error.emptyName')
      } else if (this.URIIndicatorPicture === undefined) {
        this.error.title = ''
        this.error.picture = this.$t('router.views.Indicators.Error.emptyPicture')
      } else {
        this.error.picture = ''
        if (this.initiatorIndicator === undefined) {
          this.$store.dispatch('library/addIndicator', {
            title: this.indicatorTitle,
            shortDescription: this.indicatorShortDescription,
            longDescription: this.indicatorLongDescription,
            dimensions: this.indicatorDimensions,
            shortLicence: this.indicatorShortLicence,
            shortSpdxLicence: this.indicatorShortSpdxLicence,
            longLicence: this.indicatorLongLicence,
            version: this.indicatorVersion,
            localUrl: this.indicatorLocalUrl,
            publicUrl: this.indicatorPublicUrl,
            date: this.indicatorDate,
            gitHashKey: this.indicatorGitHashKey,
            urlParams: this.indicatorUriParam,
            urlParamsSchema: this.indicatorUriParamSchema,
            isHidden: this.indicatorOnMaintenance,
            imageBlob: this.indicatorPictureBlob
          })
        } else {
          this.$store.dispatch('library/updateIndicator', {
            indicatorId: this.initiatorIndicator.indicatorId,
            title: this.indicatorTitle,
            shortDescription: this.indicatorShortDescription,
            longDescription: this.indicatorLongDescription,
            dimensions: this.indicatorDimensions,
            shortLicence: this.indicatorShortLicence,
            shortSpdxLicence: this.indicatorShortSpdxLicence,
            longLicence: this.indicatorLongLicence,
            version: this.indicatorVersion,
            localUrl: this.indicatorLocalUrl,
            publicUrl: this.indicatorPublicUrl,
            date: this.indicatorDate,
            gitHashKey: this.indicatorGitHashKey,
            urlParams: this.indicatorUriParam,
            urlParamsSchema: this.indicatorUriParamSchema,
            isHidden: this.indicatorOnMaintenance,
            imageBlob: this.indicatorPictureBlob
          })
        }
        this.closePanel() // TODO do this only if no errors?
      }
    },
    closePanel () {
      this.$emit('close')
    },
    parseJson () {
      libraryService.parseIndicatorJson(this.jsonPath)
        .then(data => {
          if (data.success) {
            this.jsonContent = data.jsonContent

            if (this.jsonContent.IdIndicator !== undefined) {
              this.indicatorTitle = this.jsonContent.Name
              this.indicatorShortDescription = this.jsonContent.DescriptionShort
              this.indicatorLongDescription = this.jsonContent.DescriptionLong
              // Beneficiaries is not used -> to be defined
              this.indicatorDimensions = this.jsonContent.Dimensions
              this.indicatorShortLicence = this.jsonContent.LicenceShortText
              this.indicatorShortSpdxLicence = this.jsonContent.LicenceShortSpdx
              this.indicatorLongLicence = this.jsonContent.LicenceLong
              this.indicatorVersion = this.jsonContent.Version
              this.indicatorPublicUrl = this.jsonContent.UriPublic
              this.indicatorLocalUrl = this.jsonContent.UriLocal
              this.indicatorDate = this.jsonContent.Date
              this.indicatorVersion = this.jsonContent.Version
              this.indicatorGitHashKey = this.jsonContent.GitHashKey
              this.indicatorUriParam = this.jsonContent.UriParam
              this.indicatorUriParamSchema = this.jsonContent.UriParamSchema
              this.indicatorOnMaintenance = true
              this.jsonPickerWizardStep = false
            }
          } else {
            this.$store.dispatch('popups/pushPopup', { message: this.$t('router.views.Indicators.parseJsonError') })
          }
        })
        .catch(err => {
          this.$store.dispatch('popups/pushPopup', { message: 'ERROR' })
          console.error(err)
        })
    },
    testUrl () {
      this.isTestDone = true
      this.isTestOK = true
    }
  }
}
</script>

<style lang="scss" scoped>

h2 {
  padding-left: 10px;
}

.form {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap:5px;
  padding-left: 20px;
  padding-right: 20px;

  p {
    margin: 0;
    padding-right: 20px;
    padding-left: 20px;
  }

  .url-test {
    p {
      display: inline;
    }
    .test-ok {
      color: green;
    }
    .test-ko {
      color: red;
    }
  }

  .maintenance {
    margin-left: 20px;
  }
}

.icon-container {
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  border-radius: 20px;
  border: none;

  .close-panel {
    font-size: 18px;
  }
}

.field {
  margin-bottom: 20px;
}

.indicator-picture-container {
  display: flex;
  padding-left: 25px;
  padding-top: 10px;
}

.indicator-picture {
  cursor: pointer;

  .indicator-picture-placeholder {
    height: 106px;
    width: 106px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    text-align: center;
  }
}

.error-message {
  margin-top: 3px;
  color: red;
  font-size: 13px;
}

.dark {
  margin-left: 200px;
}
</style>
